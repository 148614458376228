import { template } from "@ember/template-compiler";
import { DanceMix } from './media/dance-mix';
import { Looper } from './media/loop';
import { Song } from './media/song';
import type { ExerciseInstructionMedia, ExerciseMedia, ExerciseMediaDancemix, ExerciseMediaLoop, ExerciseMediaSong } from '../domain-objects';
import type { TOC } from '@ember/component/template-only';
interface MediaSignature {
    Args: {
        media: ExerciseMedia[];
    };
}
function isDanceMix(media1: ExerciseMedia | ExerciseInstructionMedia): media is ExerciseMediaDancemix {
    return (media1.__typename === 'ExerciseMediaDancemix' || media1.__typename === 'ExerciseInstructionMediaDancemix');
}
function isLoop(media1: ExerciseMedia | ExerciseInstructionMedia): media is ExerciseMediaLoop {
    return (media1.__typename === 'ExerciseMediaLoop' || media1.__typename === 'ExerciseInstructionMediaLoop');
}
function isSong(media1: ExerciseMedia | ExerciseInstructionMedia): media is ExerciseMediaSong {
    return (media1.__typename === 'ExerciseMediaSong' || media1.__typename === 'ExerciseInstructionMediaSong');
}
export const Media: TOC<MediaSignature> = template(`
  {{#each @media as |m|}}
    {{#if (isDanceMix m)}}
      <DanceMix @media={{m}} />
    {{/if}}

    {{#if (isLoop m)}}
      <Looper @media={{m}} />
    {{/if}}

    {{#if (isSong m)}}
      <Song @media={{m}} />
    {{/if}}
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
