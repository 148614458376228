import { template } from "@ember/template-compiler";
import { pageTitle } from 'ember-page-title';
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
export class TrainingDiagnosticsRoute extends Route<object> {
    static{
        template(`
    {{pageTitle "Diagnostik"}}

    {{outlet}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default CompatRoute(TrainingDiagnosticsRoute);
