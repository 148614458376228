import { template } from "@ember/template-compiler";
import { ability } from 'ember-ability';
import { AudioPlayer, AudioService } from '../../audio';
import { SpotifyPlayer } from '../../spotify';
import styles from './player.css';
const isSpotifyPlayer = ability((sweetOwner1)=>()=>{
        return sweetOwner1.service(AudioService).player === AudioPlayer.Spotify;
    });
const Player = template(`
  {{#if (isSpotifyPlayer)}}
    <div class={{styles.player}} data-player>
      <SpotifyPlayer />
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export { Player };
