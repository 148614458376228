import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { registerDestructor } from '@ember/destroyable';
import { fn } from '@ember/helper';
import { service } from 'ember-polaris-service';
import { use } from 'ember-resources';
import Task from 'ember-tasks';
import { AudioPlayer, AudioService } from '../../audio';
import { isAuthenticated } from '../abilities';
import { findTrack } from '../resources/track';
import { SpotifyService } from '../service';
import { SpotifyPlayButton } from './spotify-play-button';
import type { Track } from '../domain-objects';
interface PlayTrackSignature {
    Args: {
        track: Track | string;
        play: (track: Track) => void;
    };
}
export class PlayTrack extends Component<PlayTrackSignature> {
    @service(AudioService)
    audio: AudioService;
    @service(SpotifyService)
    spotify: SpotifyService;
    @tracked
    playStarted = false;
    constructor(owner1: unknown, args1: PlayTrackSignature['Args']){
        super(owner1, args1);
        this.audio.player = AudioPlayer.Spotify;
        registerDestructor(this, ()=>{
            this.audio.player = undefined;
            if (this.playStarted && this.spotify.client.playing) {
                this.spotify.client.pause();
            }
        });
    }
    @cached
    get load() {
        // eslint-disable-next-line @typescript-eslint/init-declarations
        let promise1: Promise<Track>;
        if (typeof this.args.track === 'string') {
            promise1 = use(this, findTrack(this.args.track)).current;
        } else {
            promise1 = new Promise((resolve1)=>resolve1(this.args.track as Track));
        }
        return Task.promise(promise1);
    }
    get intent() {
        return this.playStarted ? 'stop' : 'play';
    }
    toggle = (track1: Track)=>{
        this.playStarted = !this.playStarted;
        if (this.spotify.client.playing) {
            this.spotify.client.pause();
        } else {
            this.args.play(track1);
        }
    };
    static{
        template(`
    {{#if (isAuthenticated)}}
      {{#let this.load as |r|}}
        {{#if r.resolved}}
          <SpotifyPlayButton @intent={{this.intent}} @push={{fn this.toggle r.value}}>
            {{r.value.name}}
          </SpotifyPlayButton>
        {{/if}}
      {{/let}}
    {{else}}
      Login mit Spotify
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
