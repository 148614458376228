import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { registerDestructor } from '@ember/destroyable';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { ability } from 'ember-ability';
import { action } from 'ember-command';
import { restartableTask, timeout } from 'ember-concurrency';
import { modifier } from 'ember-modifier';
import Service, { service } from 'ember-polaris-service';
import { resource, resourceFactory, use } from 'ember-resources';
import Task from 'ember-tasks';
import { Form } from '@hokulea/ember';
import { AudioPlayer, AudioService } from '../../../supporting/audio';
import { findTrack, formatArtists, isAuthenticated, MaybeSpotifyPlayerWarning, SpotifyPlayButton, SpotifyService, WithSpotify } from '../../../supporting/spotify';
import { data } from './data';
import styles from './looper.css';
import type { Track } from '../../../supporting/spotify';
import type { LoopDescriptor, LoopTrackDescriptor } from './data';
import type { TOC } from '@ember/component/template-only';
const DEV = false;
// describing the raw data
interface LoopData extends LoopDescriptor {
    name: string;
    duration: number;
    track: Track;
    id: string;
}
interface LoopTrackData extends LoopTrackDescriptor {
    track: Track;
    loops: LoopData[];
}
export const loadLoop = resourceFactory((loop1: LoopTrackDescriptor)=>{
    return resource(async ({ use: useResource1 }): Promise<LoopTrackData> =>{
        const track1 = await useResource1(findTrack(loop1.trackId)).current;
        return {
            ...loop1,
            track: track1,
            loops: loop1.loops.map((lp1)=>{
                return {
                    id: loop1.id,
                    track: track1,
                    ...lp1,
                    name: lp1.name ?? 'default',
                    duration: lp1.end - lp1.start
                };
            })
        };
    });
});
let LoopService = class LoopService extends Service {
    @service(SpotifyService)
    spotify: SpotifyService;
    @tracked
    latency = 250;
    @tracked
    playing?: LoopData;
    @tracked
    elapsedTime = 0;
    start = async (loop1: LoopData, offset1?: number)=>{
        this.playing = loop1;
        try {
            await this.play.perform(loop1, offset1);
        // eslint-disable-next-line no-empty
        } catch  {}
    };
    play = restartableTask(async (loop1: LoopData, offset1?: number)=>{
        this.spotify.client.selectTrack(loop1.track);
        let max1 = loop1.end - loop1.start - this.latency;
        this.elapsedTime = offset1 ? (offset1 < 0 ? max1 + offset1 : offset1) : 0;
        // eslint-disable-next-line no-constant-condition
        while(true){
            await this.spotify.client.play({
                uris: [
                    loop1.track.uri
                ],
                // eslint-disable-next-line @typescript-eslint/naming-convention
                position_ms: loop1.start + this.elapsedTime // elapsed time includes the duration offset
            });
            while(this.elapsedTime < max1){
                max1 = loop1.end - loop1.start - this.latency;
                const rest1 = max1 - this.elapsedTime;
                const tick1 = rest1 <= 2000 ? rest1 : 1000;
                this.elapsedTime += tick1;
                await timeout(tick1);
            }
            this.elapsedTime = 0;
        }
    });
    stop = async ()=>{
        await this.play.last?.cancel();
        await this.spotify.client.pause();
        this.playing = undefined;
    };
};
const start = action(({ service: service1 })=>async (loop1: LoopData, offset1: number = 0)=>{
        const loopService1 = service1(LoopService);
        await loopService1.start(loop1, offset1);
    });
const stop = action(({ service: service1 })=>async ()=>{
        const loopService1 = service1(LoopService);
        await loopService1.stop();
    });
const isPlaying = ability(({ service: service1 })=>(loop1: LoopData)=>{
        const loopService1 = service1(LoopService);
        return loopService1.playing === loop1;
    });
const playingPercentage = ability(({ service: service1 })=>()=>{
        const loopService1 = service1(LoopService);
        if (!loopService1.playing) {
            return 0;
        }
        return Math.round((loopService1.elapsedTime / loopService1.playing.duration) * 100);
    });
const applyPercentage = modifier((element1, [percentage1])=>{
    (element1 as HTMLElement).style.setProperty('--percent', `${percentage1}%`);
});
const formatDuration = (ms1: number)=>{
    if (ms1 < 0) ms1 = -ms1;
    const time1 = {
        m: Math.floor(ms1 / 60000) % 60,
        s: Math.floor(ms1 / 1000) % 60,
        ms: Math.floor(ms1) % 1000
    };
    return `${String(time1.m).padStart(2, '0')}:${String(time1.s).padStart(2, '0')},${time1.ms}`;
};
let Latency = class Latency extends Component {
    @service(LoopService)
    loop: LoopService;
    updateLatency = (event1: Event)=>{
        this.loop.latency = Number.parseFloat((event1.target as HTMLInputElement).value);
    };
    get data() {
        return {
            latency: this.loop.latency
        };
    }
    static{
        template(`
    <details>
      <summary>Einstellungen</summary>

      <Form @data={{this.loop}} @dataMode="mutable" as |f|>
        <f.Number @name="latency" @label="Latenz [ms]" />
      </Form>
    </details>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
interface PlayButtonSignature {
    Args: {
        loop: LoopData;
    };
}
const PlayButton: TOC<PlayButtonSignature> = template(`
  {{#if (isPlaying @loop)}}
    <SpotifyPlayButton
      @intent="stop"
      class={{styles.playbutton}}
      data-playing
      {{on "click" (stop)}}
      {{applyPercentage (playingPercentage)}}
    >
      Stop
    </SpotifyPlayButton>
  {{else}}
    <SpotifyPlayButton class={{styles.playbutton}} {{on "click" (fn (start) @loop 0)}}>
      Play
    </SpotifyPlayButton>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface LoopSignature {
    Args: {
        track: LoopTrackDescriptor;
        loop?: string;
    };
}
let Loop = class Loop extends Component<LoopSignature> {
    @service(LoopService)
    loop: LoopService;
    @service(AudioService)
    audio: AudioService;
    loaded = false;
    constructor(owner1: unknown, args1: LoopSignature['Args']){
        super(owner1, args1);
        this.audio.player = AudioPlayer.Spotify;
        registerDestructor(this, ()=>{
            this.audio.player = undefined;
            if (this.loaded && this.load.resolved) {
                for (const loop1 of this.load.value.loops){
                    if (this.loop.playing === loop1) {
                        this.loop.stop();
                    }
                }
            }
        });
    }
    @cached
    get load() {
        const promise1 = use(this, loadLoop(this.args.track)).current;
        // eslint-disable-next-line ember/no-side-effects
        this.loaded = true;
        return Task.promise(promise1);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getLoop = (track1: LoopTrackData)=>{
        if (track1.loops.length === 1) {
            return track1.loops[0];
        } else if (this.args.loop) {
            return track1.loops.find((loop1)=>loop1.name === this.args.loop);
        }
    };
    static{
        template(`
    {{#if (isAuthenticated)}}
      {{#let this.load as |r|}}
        {{#if r.resolved}}
          {{#let (this.getLoop r.value) as |l|}}
            {{#if l}}
              <PlayButton @loop={{l}} />
            {{/if}}
          {{/let}}
        {{/if}}
      {{/let}}
    {{else}}
      Login mit Spotify
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
interface LoopCardSignature {
    Args: {
        loop: LoopTrackDescriptor;
    };
}
let LoopCard = class LoopCard extends Component<LoopCardSignature> {
    @service(LoopService)
    loop: LoopService;
    constructor(owner1: unknown, args1: LoopCardSignature['Args']){
        super(owner1, args1);
        registerDestructor(this, ()=>{
            if (this.load.resolved) {
                for (const loop1 of this.load.value.loops){
                    if (this.loop.playing === loop1) {
                        this.loop.stop();
                    }
                }
            }
        });
    }
    @cached
    get load() {
        const promise1 = use(this, loadLoop(this.args.loop)).current;
        return Task.promise(promise1);
    }
    static{
        template(`
    <article class={{styles.card}}>
      {{#let this.load as |r|}}
        {{#if r.resolved}}
          <div class={{styles.header}}>
            <strong>{{r.value.track.name}}</strong>
            <small>{{formatArtists r.value.track.artists}}</small>
          </div>

          {{#each r.value.loops as |loop|}}
            <div class={{styles.loop}}>
              <div>
                <time>{{formatDuration loop.duration}}</time>
                <span>{{loop.description}}</span>
              </div>

              <div class={{styles.buttons}}>
                {{#if DEV}}
                  <SpotifyPlayButton {{on "click" (fn (start) loop -10000)}}>-10</SpotifyPlayButton>
                  <SpotifyPlayButton {{on "click" (fn (start) loop -5000)}}>-5</SpotifyPlayButton>
                {{/if}}
                <PlayButton @loop={{loop}} />
              </div>
            </div>
          {{/each}}
        {{/if}}
      {{/let}}
    </article>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
let Game = class Game extends Component {
    @service(AudioService)
    audio: AudioService;
    constructor(owner1: unknown, args1: object){
        super(owner1, args1);
        this.audio.player = AudioPlayer.Spotify;
        registerDestructor(this, ()=>{
            this.audio.player = undefined;
        });
    }
    static{
        template(`
    <Latency />

    <MaybeSpotifyPlayerWarning />

    {{#each data as |loop|}}
      <LoopCard @loop={{loop}} />
    {{/each}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
const Looper: TOC<object> = template(`
  <h1>Loops</h1>

  <WithSpotify>
    <Game />
  </WithSpotify>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export { Loop, Looper };
