import { template } from "@ember/template-compiler";
import styles from './features.css';
import type { TOC } from '@ember/component/template-only';
const Features: TOC<{
    Element: HTMLDivElement;
    Blocks: {
        default: [{
                list: string;
            }];
    };
}> = template(`
  <div class={{styles.features}} ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Features;
