import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { fn } from '@ember/helper';
import { concat, hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { next } from '@ember/runloop';
import { use } from 'ember-resources';
import { eq } from 'ember-truth-helpers';
import { TrackedObject, TrackedSet } from 'tracked-built-ins';
import { v4 as uuid } from 'uuid';
import { Button, Form, Icon, IconButton } from '@hokulea/ember';
import { findAwfulPractices } from '../../choreography';
import styles from './bingo.css';
import type { Awfulpractice } from '../../choreography';
import type { TOC } from '@ember/component/template-only';
interface TileSignature {
    Args: {
        principle: Awfulpractice;
        selected: boolean;
        winner: boolean;
        select: () => void;
    };
}
const Tile: TOC<TileSignature> = template(`
  <div
    class={{styles.tile}}
    data-winner={{@winner}}
    data-selected={{@selected}}
    {{on "click" @select}}
  >
    {{@principle.title}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
let Bingo = class Bingo {
    @tracked
    principles: Awfulpractice[];
    @tracked
    selection: Set<Awfulpractice> = new TrackedSet();
    @tracked
    winner: Set<Awfulpractice> = new TrackedSet();
    @tracked
    finished: boolean = false;
    private static STORAGE_PRINCIPLES: string = 'bullshit_bingo.principles';
    private static STORAGE_SELECTION: string = 'bullshit_bingo.selection';
    static hasSavegame() {
        const principles1 = window.localStorage.getItem(Bingo.STORAGE_PRINCIPLES);
        return Boolean(principles1);
    }
    static loadSavegame(): Bingo | undefined {
        const data1 = window.localStorage.getItem(Bingo.STORAGE_PRINCIPLES);
        const selection1 = window.localStorage.getItem(Bingo.STORAGE_SELECTION);
        if (!data1) {
            return;
        }
        const principles1 = JSON.parse(data1) as Awfulpractice[];
        if (principles1) {
            const game1 = new Bingo(principles1);
            if (selection1) {
                for (const s1 of JSON.parse(selection1)){
                    game1.selection.add(game1.principles.find((p1)=>p1.id === s1.id) as Awfulpractice);
                }
                game1.checkForWin();
            }
            return game1;
        }
        return;
    }
    constructor(principles1: Awfulpractice[]){
        this.principles = principles1;
        // clear local storage for a fresh game
        window.localStorage.removeItem(Bingo.STORAGE_PRINCIPLES);
        window.localStorage.removeItem(Bingo.STORAGE_SELECTION);
        // save new game
        this.persistPrinciples();
    }
    select = (principle1: Awfulpractice)=>{
        if (this.selection.has(principle1)) {
            this.selection.delete(principle1);
        } else {
            this.selection.add(principle1);
        }
        // reset in case of unselect
        this.finished = false;
        this.winner.clear();
        this.checkForWin();
    };
    private checkForWin() {
        const winner1 = this.findWinner();
        if (winner1 !== undefined) {
            const [line1, index1] = winner1;
            if (line1 === 'row') {
                const start1 = (index1 - 1) * 5;
                for(let i1 = start1; i1 < start1 + 5; i1++){
                    this.winner.add(this.principles[i1]);
                }
            }
            if (line1 === 'col') {
                for(let i1 = 0; i1 < this.principles.length; i1++){
                    if (this.getColumn(i1 + 1) === index1) {
                        this.winner.add(this.principles[i1]);
                    }
                }
            }
            if (line1 === 'cross') {
                for(let i1 = 0; i1 < this.principles.length; i1++){
                    const row1 = this.getRow(i1 + 1);
                    const col1 = this.getColumn(i1 + 1);
                    if (index1 === 0 && row1 === col1) {
                        this.winner.add(this.principles[i1]);
                    }
                    if (index1 === 1 && row1 + col1 === 6) {
                        this.winner.add(this.principles[i1]);
                    }
                }
            }
            this.finished = true;
        }
        this.persistSelection();
    }
    findWinner(): [string, number] | undefined {
        if (this.principles.length === 0) {
            return undefined;
        }
        const rows1: boolean[][] = [
            [],
            [],
            [],
            [],
            []
        ];
        const cols1: boolean[][] = [
            [],
            [],
            [],
            [],
            []
        ];
        const cross1: boolean[][] = [
            [],
            []
        ];
        for(let i1 = 0; i1 < this.principles.length; i1++){
            const selected1 = this.selection.has(this.principles[i1]);
            const index1 = i1 + 1;
            const row1 = this.getRow(index1);
            const col1 = this.getColumn(index1);
            rows1[row1 - 1].push(selected1);
            cols1[col1 - 1].push(selected1);
            if (row1 === col1) {
                cross1[0].push(selected1);
            }
            if (row1 + col1 === 6) {
                cross1[1].push(selected1);
            }
        }
        const lines1 = [
            rows1,
            cols1,
            cross1
        ].reduce((acc1, val1)=>acc1.concat(val1), []);
        const winnerLine1 = lines1.findIndex((line1)=>line1.every((v1)=>v1 === true));
        if (winnerLine1 >= 0 && winnerLine1 <= 4) {
            return [
                'row',
                winnerLine1 + 1
            ];
        } else if (winnerLine1 >= 5 && winnerLine1 <= 9) {
            return [
                'col',
                winnerLine1 + 1 - 5
            ];
        } else if (winnerLine1 >= 10) {
            return [
                'cross',
                winnerLine1 - 10
            ];
        }
        return undefined;
    }
    private getRow(index1: number): number {
        return Math.ceil(index1 / 5);
    }
    private getColumn(index1: number): number {
        const col1 = index1 % 5;
        return col1 === 0 ? 5 : col1;
    }
    private persistPrinciples() {
        window.localStorage.setItem(Bingo.STORAGE_PRINCIPLES, JSON.stringify(this.principles));
    }
    private persistSelection() {
        window.localStorage.setItem(Bingo.STORAGE_SELECTION, JSON.stringify(Array.from(this.selection)));
    }
};
interface Counter {
    id: string;
    name: string;
    count: number;
}
let Counters = class Counters {
    private static STORAGE_COUNTER: string = 'bullshit_bingo.counter';
    private static STORAGE_ACTIVE_COUNTER_ID: string = 'bullshit_bingo.active_counter_id';
    @tracked
    counters: Record<string, Counter> = new TrackedObject();
    @tracked
    activeCounter: Counter;
    constructor(){
        this.load();
        if (Object.keys(this.counters).length === 0) {
            next(this, this.init);
        }
    }
    load() {
        const activeCounter1 = window.localStorage.getItem(Counters.STORAGE_ACTIVE_COUNTER_ID);
        const data1 = window.localStorage.getItem(Counters.STORAGE_COUNTER);
        const counters1: Record<string, Counter> = data1 ? new TrackedObject(JSON.parse(data1)) : {};
        for (const [id1, counter1] of Object.entries(counters1)){
            this.counters[id1] = new TrackedObject(counter1) as unknown as Counter;
        }
        if (activeCounter1 && Object.keys(this.counters).includes(activeCounter1)) {
            this.activeCounter = this.counters[activeCounter1];
        }
    }
    init() {
        const id1 = uuid();
        this.counters[id1] = new TrackedObject({
            id: id1,
            name: 'Standard',
            count: 0
        });
        this.activeCounter = this.counters[id1];
        this.persistCounters();
    }
    activateCounter = (id1: string)=>{
        if (this.counters[id1]) {
            this.activeCounter = this.counters[id1];
            window.localStorage.setItem(Counters.STORAGE_ACTIVE_COUNTER_ID, id1);
        }
    };
    renameCounter = (id1: string, name1: string)=>{
        this.counters[id1].name = name1;
        // if (id === this.activeCounter.id) {
        //   this.activeCounter = this.counters[id];
        // }
        this.persistCounters();
    };
    incrementCounter(id1: string) {
        this.counters[id1].count++;
        // if (id === this.activeCounter.id) {
        //   this.activeCounter = this.counters[id];
        // }
        this.persistCounters();
    }
    incrementActiveCounter = ()=>{
        this.incrementCounter(this.activeCounter.id);
    };
    newCounter = (name1: string)=>{
        const cnt1 = new TrackedObject({
            id: uuid(),
            name: name1,
            count: 0
        });
        this.counters[cnt1.id] = cnt1;
        this.persistCounters();
    };
    deleteCounter = (id1: string)=>{
        delete this.counters[id1];
        this.persistCounters();
    };
    persistCounters() {
        window.localStorage.setItem(Counters.STORAGE_COUNTER, JSON.stringify(this.counters));
        window.localStorage.setItem(Counters.STORAGE_ACTIVE_COUNTER_ID, this.activeCounter.id);
    }
};
function has(set1: Set<unknown>, value1: unknown): boolean {
    return set1.has(value1);
}
let CounterManager = class CounterManager extends Component<{
    Args: {
        counters: Counters;
    };
}> {
    newCounter = (data1: {
        name: string;
    })=>{
        const { name: name1 } = data1;
        this.args.counters.newCounter(name1);
        data1.name = '';
    };
    renameCounter = (id1: string, data1: {
        name: string;
    })=>{
        this.args.counters.renameCounter(id1, data1.name);
    };
    static{
        template(`
    <div class={{styles.counters}}>
      <h2>Zähler</h2>

      <p>
        Zähler können verwendet werden, um an bestimmten Ereignissen die Bingos zu zählen (zum
        Beispiel der Videoanalyse vergangener Wettkämpfe).
      </p>

      {{#each-in @counters.counters as |id counter|}}
        <Form
          @data={{hash name=counter.name}}
          @submit={{fn this.renameCounter id}}
          class={{styles.form}}
          as |f|
        >
          <f.Text @name="name" @label={{concat "Zählerstand: " counter.count}} />

          <f.Submit><Icon @icon="pencil" /></f.Submit>

          {{#if (eq id @counters.activeCounter.id)}}
            <IconButton @icon="check" @disabled={{true}} @label="Zähler aktiv" />
          {{else}}
            <IconButton
              @icon="arrow-right"
              @push={{fn @counters.activateCounter id}}
              @label="Zähler aktivieren"
            />
          {{/if}}

          <IconButton
            @intent="danger"
            @importance="subtle"
            @icon="trash"
            @push={{fn @counters.deleteCounter id}}
            @label="Zähler löschen"
          />
        </Form>
      {{/each-in}}

      <h3>Neuer Zähler</h3>

      <Form @data={{hash name=""}} @submit={{this.newCounter}} class="{{styles.form}}" as |f|>
        <f.Text @name="name" @label="Name für den Zähler" />

        <f.Submit><Icon @icon="plus" /></f.Submit>
      </Form>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default class BingoComponent extends Component {
    principlesLoader = use(this, findAwfulPractices);
    @tracked
    principles: Awfulpractice[] = [];
    @tracked
    gamePrinciples: Awfulpractice[] = [];
    @tracked
    display: string = 'game';
    @tracked
    game?: Bingo;
    counters = new Counters();
    constructor(owner1: unknown, args1: object){
        super(owner1, args1);
        this.load();
    }
    async load() {
        this.principles = await this.principlesLoader.current;
        if (Bingo.hasSavegame()) {
            this.game = Bingo.loadSavegame();
        }
    }
    toggle = ()=>{
        this.display = this.display === 'game' ? 'counter' : 'game';
    };
    start = ()=>{
        const principles1 = this.principles.sort(()=>0.5 - Math.random()).slice(0, 25);
        this.game = new Bingo(principles1);
    };
    reset = ()=>{
        this.start();
    };
    newGame = ()=>{
        this.counters.incrementActiveCounter();
        this.start();
    };
    static{
        template(`
    <div class={{styles.bingo}}>
      <nav>
        <ul>
          <li>
            <Button @spacing="-1" @push={{this.toggle}}>
              {{#if (eq this.display "game")}}
                Zähler
              {{else}}
                Spielen
              {{/if}}
            </Button>
          </li>
          <li>
            {{this.counters.activeCounter.name}}:
            <span>{{this.counters.activeCounter.count}}</span>
          </li>
        </ul>

        {{#if (eq this.display "game")}}
          <ul>
            <li class="{{styles.bingoText}} {{if this.game.finished styles.bingoTextVisible}}">
              <span>B</span>
              <span>i</span>
              <span>n</span>
              <span>g</span>
              <span>o</span>
              <span>!</span>
            </li>
          </ul>
          <ul>
            {{#if this.game.finished}}
              <li><Button @spacing="-1" @importance="subtle" @push={{this.newGame}}>Nochmal Spielen</Button></li>
            {{else}}
              <li><Button @spacing="-1" @importance="subtle" @push={{this.reset}}>Neues Spiel</Button></li>
            {{/if}}
          </ul>
        {{/if}}
      </nav>

      {{#if (eq this.display "game")}}
        {{#if this.game}}
          <div class={{styles.playfield}}>
            {{#each this.game.principles as |principle|}}
              <Tile
                @principle={{principle}}
                @selected={{has this.game.selection principle}}
                @winner={{has this.game.winner principle}}
                @select={{fn this.game.select principle}}
              />
            {{/each}}
          </div>
        {{else}}
          <Button @push={{this.newGame}}>Neues Spiel</Button>
        {{/if}}
      {{else}}
        <CounterManager @counters={{this.counters}} />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
